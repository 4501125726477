import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import StepMenu from "../stepMenu";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";

import { toast } from "react-hot-toast";
import { Spin, Select } from "antd";

import ConfirmModal from "../../confirmModal";
import {
  lettersOnly,
  lowerCase,
  momentDate,
  numberToMonth,
  upperCase,
  S3_BUCKET_HOME_PATH,
  APP_TYPE,
  COLLEGE,
} from "../../../utils";
import { COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY } from "../../../utils/data";
import { Link } from "react-router-dom";

const { Option } = Select;

const StepTwo = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [validated, setValidated] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [captcha, setCaptcha] = useState([]);
  const [applicant, setApplicant] = useState([]);
  const [hscSubjects, setHscSubjects] = useState([]);

  const [minMark, setMinMarks] = useState(70);
  const [maxMark, setMaxMark] = useState(200);
  const [community, setCommunity] = useState("");
  const [subjectCount, setSubjectCount] = useState(0);
  const [board, setBoard] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [savedMarks, setSavedMarks] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  const getMarksInfo = (sId, info = false) => {
    return {
      subject_id: sId || "",
      subjectname: "",
      subject_information: info,
      mark_scored: "",
      mark_maximum: maxMark,
      pass_month: 4,
      pass_year: new Date().getFullYear(),
      register_no: "",
      no_of_attempts: "",
      scored_total: "",
      max_total: "",
      part3_total: "",
      percentage: "",
    };
  };

  const [marks, setMarks] = useState([]);

  useEffect(() => {
    loadApplicantInfo();
    loadHscSubjects();

    if (props.pageType && props.pageType == "edit") loadData();
  }, []);

  const loadData = () => {
    setDataLoader(true);
    setLoader(true);
    const form = new FormData();
    form.append("api_code", context.user.api_code);
    if (APP_TYPE == "SAAS") form.append("sadb", COLLEGE);
    axios.post(ServiceUrl.UG.PERSONAL_MARKS_PREVIEW, form).then((res) => {
      if (res["data"].status == "1") {
        let d = res["data"].data.basic;
        let m = res["data"].data.marks;
        setBaisc(res["data"].data.basic);
        setSavedMarks(res["data"].data.marks);
        setCommunity(d.community);
        setBoard(d.board);

        let mr = [];
        m.map((item, i) => {
          mr.push({
            id: item.id,
            subject_id: item.subject_id,
            subjectname: item.subjectname,
            mark_scored: parseFloat(item.mark_scored),
            mark_maximum: item.mark_maximum,
            pass_month: item.pass_month,
            pass_year: item.pass_year,
            register_no: item.register_no,
            no_of_attempts: item.no_of_attempts,
            scored_total: item.scored_total,
            max_total: item.max_total,
            part3_total: item.part3_total,
            percentage: item.percentage,
          });
        });

        setMarks(mr);
        setDataLoader(false);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const marksField = (fieldName) => {
    if (savedMarks && savedMarks.length > 0 && savedMarks[0][fieldName])
      return savedMarks[0][fieldName];
  };

  useEffect(() => {
    //if (!props.pageType && props.pageType != "edit") {
    {
      let m = [];
      for (let i = 0; i < subjectCount; i++) {
        if (i == 0 && board == "state") m.push(getMarksInfo(5));
        else if (i == 1 && board == "state") m.push(getMarksInfo(6));
        else m.push(getMarksInfo(false, i == 0 ? "Language" : ""));
      }
      setMarks(m);
    }
  }, [minMark, maxMark, subjectCount, board]);

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.user.api_code);
      if (APP_TYPE == "SAAS") form.append("sadb", COLLEGE);
      axios.post(ServiceUrl.UG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;
          console.log(d);
          /*setMinMarks(d.year_of_passing == "2019" ? 35 : 70);
          setMaxMark(d.year_of_passing == "2019" ? 100 : 200);*/
          setMinMarks(35);
          setMaxMark(100);
          setSubjectCount(d.board == "state" ? 6 : 5);
          setBoard(d.board);
          setCommunity(d.community);
          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const loadHscSubjects = () => {
    try {
      setLoader(true);
      setHscSubjects([]);
      let url = "";
      if (APP_TYPE == "SAAS") url = "?sadb=" + COLLEGE;
      axios.get(ServiceUrl.UG.HSC_SUBJECTS + url).then((res) => {
        if (res["data"].status == "1") {
          setHscSubjects(res["data"].data);
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const days = () => {
    let rv = [];
    Array.from({ length: 31 }, (v, i) => {
      rv.push(<option value={i + 1}>{i + 1}</option>);
    });
    return rv;
  };

  const months = (sel) => {
    let rv = [];
    Array.from({ length: 12 }, (v, i) => {
      let n = ("0" + (i + 1)).slice(-2);
      rv.push(
        <option
          value={("0" + n).slice(-2)}
          selected={sel == i + 1 ? "selected" : ""}
        >
          {numberToMonth(n)}
        </option>
      );
    });
    return rv;
  };

  const years = (sel) => {
    let rv = [];
    Array.from({ length: 10 }, (v, i) => {
      let n = new Date().getFullYear() - i;
      rv.push(
        <option value={n} selected={sel == n ? "selected" : ""}>
          {n}
        </option>
      );
    });
    return rv;
  };

  const marksInputOnBlur = (item, e) => {
    let ds = e.target.dataset;
    let v = e.target.value;

    if (
      parseFloat(v) < parseFloat(ds.min) ||
      parseFloat(v) > parseFloat(ds.max)
    ) {
      toast.error("Enter correct mark");
      e.target.value = "";
      return;
    }
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index]["mark_scored"] = v;
      setMarks(m);
    }
  };

  const getSubjectOptions = () => {
    let rv = [];
    hscSubjects.map((item, i) => {
      let index = marks.findIndex((obj) => obj.subject_id == item.id);
      //if (index == -1) rv.push(<Option value={item.id}>{item.name}</Option>);
      if (index == -1) rv.push({ value: item.id, label: item.name });
    });
    return rv;
  };

  const handleSubjectChange = (index, v) => {
    let m = [...marks];
    m[index]["subject_id"] = v;
    setMarks(m);
  };

  const handleInputChange = (item, fieldName, e) => {
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index][fieldName] = e.target.value;
      setMarks(m);
    }
    if (fieldName == "no_of_attempts" && e.target.value == 1) {
      if (m[index - 1]["no_of_attempts"] == 1) {
        m[index]["register_no"] = m[index - 1]["register_no"];
      } else {
        m[index]["register_no"] = m[index - 2]["register_no"];
      }
    }
  };

  const getTotalByFieldName = (fieldName) => {
    let m = marks;
    let total = 0;
    m.map((item, i) => {
      let v = item[fieldName];
      if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
    });
    return parseFloat(total);
  };

  const part3Total = () => {
    let m = marks;
    let total = 0;

    m.map((item, i) => {
      //if (item.subject_id != 5 && item.subject_id != 6) {

      if (
        applicant.board == "state" &&
        item.subject_id != 5 &&
        item.subject_id != 6
      ) {
        let v = item.mark_scored;
        if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
      } else if (applicant.board != "state" && i != 0) {
        let v = item.mark_scored;
        if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
      }
    });
    return parseFloat(total);
  };

  const part3Percentage = () => {
    let total = part3Total();
    return parseFloat((total / 400) * 100).toFixed(2);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      toast.error("Please fill all the required fields.");
      return;
    }

    let m = marks.find((item) => !item.subject_id);
    if (m) {
      toast.error("Please select all the subjects and marks");
      return;
    }

    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);

    let url = ServiceUrl.UG.STEP_TWO;
    if (props.pageType && props.pageType == "edit")
      url = ServiceUrl.UG.UPDATE_STEP_TWO;

    axios.post(url, $("#frmStepII").serialize()).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        setIsRedirect(true);
        if (props.pageType && props.pageType == "edit") props.history.push("/");
      } else {
        toast.error(res["data"].message || "Error");

        context.setLoader(false);
        setLoader(false);
        loadCaptcha();
      }
    });
  };

  const loadCaptcha = () => {
    try {
      setLoader(true);
      let url = "";
      if (APP_TYPE == "SAAS") url = "?sadb=" + COLLEGE;
      axios.get(ServiceUrl.ADMISSION.CAPTCHA + url).then((res) => {
        setCaptcha(res["data"]);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };
  console.log(basic);
  const handleChange = (e, item) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 12) value = value.slice(0, 12); // Limit max length

    handleInputChange(item, "register_no", { target: { value } });
  };

  return (
    <>
      <div className="container mb-3">
        {context.settingValue("register_preferred_course") == 1 ? (
          <div className="container bootstrap snippets bootdey">
            <div className="row">
              <ul className="notes">
                <li>
                  <div className="rotate-2 red-bg">
                    <h4>
                      PRE-REGISTRATION SUBMITTED SUCCESSFULLY. இக்கல்லூரியில்
                      சேர்க்கைக்கு விண்ணப்பிக்க, +2 தேர்வு முடிவுகள் வெளியான
                      பின், மேலும் 4 படிகள்/தகவல்களை பூர்த்தி செய்து, தங்கள்
                      விண்ணப்பத்தை பூர்த்தி செய்ய வேண்டும்.
                    </h4>
                    <p></p>
                    <a href="#" className="text-danger pull-right">
                      <i className="fa fa-trash-o "></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <StepMenu activeKey="step2" pageType={props.pageType} />

                {dataLoader && (
                  <div className="text-center my-5">
                    <Spinner />
                  </div>
                )}
                {!dataLoader && applicant.board && (
                  <Card>
                    <Card.Header>
                      <div className="float-start">
                        Name :{"  "}
                        <b className="text-uppercase">
                          {applicant.name} {applicant.initial}
                        </b>
                      </div>
                      <div className="float-end">
                        Registration No : {"  "}
                        <b>{applicant.user_code}</b>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Spin spinning={loader}>
                        <Form
                          noValidate
                          validated={validated}
                          encType="multipart/form-data"
                          id="frmStepII"
                          method="post"
                          onSubmit={handleFormSubmit}
                        >
                          <input
                            type="hidden"
                            name="sadb"
                            value={APP_TYPE == "SAAS" ? COLLEGE : ""}
                          />
                          <input
                            type="hidden"
                            name="i__userId"
                            value={context.user.user_id}
                          />
                          <input
                            type="hidden"
                            name="i__userCode"
                            value={context.user.user_code}
                          />
                          <input
                            type="hidden"
                            name="i__apiCode"
                            value={context.user.api_code}
                          />
                          <input
                            type="hidden"
                            name="marks"
                            value={JSON.stringify(marks)}
                          />

                          <Row className="context_box_title mt-2">
                            <Col md={12}>
                              <h5>Details of Qualification</h5>
                            </Col>
                          </Row>
                          {S3_BUCKET_HOME_PATH == "tdmns/" ? (
                            <>
                              <input
                                type="hidden"
                                name="i__medium"
                                value="english"
                              />

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Type of School
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    as="select"
                                    className="form-select form-select-sm fw-bold"
                                    name="i__schoolType"
                                    size="sm"
                                    defaultValue={lowerCase(
                                      marksField("school_type")
                                    )}
                                    required
                                  >
                                    <option value=""> -Select- </option>
                                    <option value="govt"> Government </option>
                                    <option value="govt aided">
                                      {" "}
                                      Government Aided{" "}
                                    </option>
                                    <option value="private"> Private </option>
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Name of the School Studied
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__school"
                                    size="sm"
                                    defaultValue={marksField("school_name")}
                                    placeholder="Name of the School"
                                    onKeyPress={(e) => lettersOnly(e)}
                                    required
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 ">
                                <Col md={4}>
                                  <label>
                                    Place of the School Studied
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__schoolPlace"
                                    size="sm"
                                    placeholder="Place of the School"
                                    defaultValue={marksField("school_place")}
                                    required
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              {applicant.board != "state" ? (
                                <>
                                  <input
                                    type="hidden"
                                    name="i__medium"
                                    value="english"
                                  />

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Type of School
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        as="select"
                                        className="form-select form-select-sm fw-bold"
                                        name="i__schoolType"
                                        size="sm"
                                        defaultValue={lowerCase(
                                          marksField("school_type")
                                        )}
                                        required
                                      >
                                        <option value=""> -Select- </option>
                                        <option value="govt">
                                          {" "}
                                          Government{" "}
                                        </option>
                                        <option value="govt aided">
                                          {" "}
                                          Government Aided{" "}
                                        </option>
                                        <option value="private">
                                          {" "}
                                          Private{" "}
                                        </option>
                                      </Form.Control>
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Name of the School Studied
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__school"
                                        size="sm"
                                        defaultValue={marksField("school_name")}
                                        placeholder="Name of the School"
                                        onKeyPress={(e) => lettersOnly(e)}
                                        required
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 ">
                                    <Col md={4}>
                                      <label>
                                        Place of the School Studied
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__schoolPlace"
                                        size="sm"
                                        placeholder="Place of the School"
                                        defaultValue={marksField(
                                          "school_place"
                                        )}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <>
                                  <table
                                    width="100%"
                                    className="table table-sm table-bordered mt-2"
                                  >
                                    <thead>
                                      <tr className="bg-light">
                                        <th width="100">className</th>
                                        <th>School Name</th>
                                        <th width="130">Medium</th>
                                        <th width="200">School Type</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="fw-bold" align="center">
                                          6th - 10th
                                        </td>
                                        <td>
                                          <Form.Control
                                            type="text"
                                            name="6_to_10_school_name"
                                            className="fw-bold text-uppercase"
                                            placeholder="School Name"
                                            defaultValue={marksField(
                                              "6_to_10_school_name"
                                            )}
                                            size="sm"
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            as="select"
                                            name="6_to_10_medium"
                                            className="fw-bold form-select form-select-sm"
                                            defaultValue={marksField(
                                              "6_to_10_medium"
                                            )}
                                            size="sm"
                                            required
                                          >
                                            <option value="">-SELECT-</option>
                                            <option value="TAMIL">TAMIL</option>
                                            <option value="ENGLISH">
                                              ENGLISH
                                            </option>
                                          </Form.Control>
                                        </td>
                                        <td>
                                          <Form.Control
                                            as="select"
                                            name="6_to_10_school_type"
                                            className="fw-bold form-select form-select-sm"
                                            defaultValue={marksField(
                                              "6_to_10_school_type"
                                            )}
                                            size="sm"
                                            required
                                          >
                                            <option value="">-SELECT-</option>
                                            <option value="GOVERNMENT">
                                              GOVERNMENT
                                            </option>
                                            <option value="GOVERNMENT AIDED">
                                              GOVERNMENT AIDED
                                            </option>
                                            <option value="PRIVATE">
                                              PRIVATE
                                            </option>
                                          </Form.Control>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="fw-bold" align="center">
                                          11th - 12th
                                        </td>
                                        <td>
                                          <Form.Control
                                            type="text"
                                            name="11_to_12_school_name"
                                            className="fw-bold text-uppercase"
                                            placeholder="School Name"
                                            defaultValue={marksField(
                                              "11_to_12_school_name"
                                            )}
                                            size="sm"
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            as="select"
                                            name="11_to_12_medium"
                                            className="fw-bold form-select form-select-sm"
                                            defaultValue={marksField(
                                              "11_to_12_medium"
                                            )}
                                            size="sm"
                                            required
                                          >
                                            <option value="">-SELECT-</option>
                                            <option value="TAMIL">TAMIL</option>
                                            <option value="ENGLISH">
                                              ENGLISH
                                            </option>
                                          </Form.Control>
                                        </td>
                                        <td>
                                          <Form.Control
                                            as="select"
                                            name="11_to_12_school_type"
                                            className="fw-bold form-select form-select-sm"
                                            defaultValue={marksField(
                                              "11_to_12_school_type"
                                            )}
                                            size="sm"
                                            required
                                          >
                                            <option value="">-SELECT-</option>
                                            <option value="GOVERNMENT">
                                              GOVERNMENT
                                            </option>
                                            <option value="GOVERNMENT AIDED">
                                              GOVERNMENT AIDED
                                            </option>
                                            <option value="PRIVATE">
                                              PRIVATE
                                            </option>
                                          </Form.Control>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </>
                          )}
                          <Row className="context_box_title mt-3">
                            <Col md={12}>
                              <h5>Details of Marks Obtained</h5>
                            </Col>
                          </Row>

                          <Row className="mt-3">
                            <span
                              style={{
                                color: "red",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              Please enter your 12 marks only
                            </span>
                            <Col md={12}>
                              <div className="table-responsive">
                                <table className="table table-sm table-bordered hsc-mark-table">
                                  <thead className="bg-light">
                                    <tr>
                                      <th width="250">Subject</th>
                                      <th>Marks Scored</th>
                                      <th>Maximum Marks</th>
                                      <th>Month & Year of Passing</th>
                                      <th>No.of Attempts</th>
                                      <th>Register No</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {marks.map((item, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>
                                            {item.subject_information && (
                                              <span>
                                                {item.subject_information}
                                              </span>
                                            )}
                                            {board == "state" && i == 0 ? (
                                              "Part-I :Language "
                                            ) : board == "state" && i == 1 ? (
                                              "Part-II : English  "
                                            ) : (
                                              <Select
                                                showSearch
                                                style={{ width: "100%" }}
                                                placeholder="Select Subject"
                                                optionFilterProp="children"
                                                className="fw-bold"
                                                filterOption={(input, option) =>
                                                  lowerCase(
                                                    option?.label ?? ""
                                                  ).includes(lowerCase(input))
                                                }
                                                onChange={(v) =>
                                                  handleSubjectChange(i, v)
                                                }
                                                defaultValue={item.subjectname}
                                                options={getSubjectOptions()}
                                              ></Select>
                                            )}
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              data-min={minMark}
                                              data-max={maxMark}
                                              min={minMark}
                                              max={maxMark}
                                              onWheel={(e) => e.target.blur()}
                                              onBlur={(e) =>
                                                marksInputOnBlur(item, e)
                                              }
                                              required
                                              defaultValue={item.mark_scored}
                                              step=".01"
                                              disabled={!item.subject_id}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              value={item.mark_maximum}
                                              disabled={!item.subject_id}
                                              required
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              as="select"
                                              size="sm"
                                              className="fw-bold"
                                              disabled={!item.subject_id}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  item,
                                                  "pass_month",
                                                  e
                                                )
                                              }
                                              required
                                            >
                                              {months(item.pass_month)}
                                            </Form.Control>
                                            <Form.Control
                                              as="select"
                                              size="sm"
                                              className="fw-bold"
                                              disabled={!item.subject_id}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  item,
                                                  "pass_year",
                                                  e
                                                )
                                              }
                                              required
                                            >
                                              {years(item.pass_year)}
                                            </Form.Control>
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              value={item.no_of_attempts}
                                              disabled={!item.subject_id}
                                              min={1}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  item,
                                                  "no_of_attempts",
                                                  e
                                                )
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              required
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              value={item.register_no}
                                              disabled={!item.subject_id}
                                              min={0}
                                              maxLength={12}
                                              onChange={(e) =>
                                                handleChange(e, item)
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              required
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td>Total</td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          className="fw-bold"
                                          size="sm"
                                          name="scored_total"
                                          value={getTotalByFieldName(
                                            "mark_scored"
                                          )}
                                          required
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          className="fw-bold"
                                          size="sm"
                                          name="max_total"
                                          value={getTotalByFieldName(
                                            "mark_maximum"
                                          )}
                                          required
                                        />
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Marks scored in Part III out of{" "}
                                {maxMark == 200 ? "800" : "400"}
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={2}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="part3_total"
                                size="sm"
                                value={part3Total()}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={4}>
                              <label>
                                Part-III Percentage (%)
                                <span className="text-danger"> *</span>
                              </label>
                            </Col>
                            <Col md={2}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="part3_percentage"
                                size="sm"
                                value={part3Percentage()}
                                required
                              />
                            </Col>
                          </Row>

                          {lowerCase(community) == "oc" && (
                            <input
                              type="hidden"
                              name="i__community"
                              value={lowerCase(community)}
                            />
                          )}
                          {S3_BUCKET_HOME_PATH == "tdmns/" ||
                          S3_BUCKET_HOME_PATH == "stjeromes/" ? (
                            <>
                              {lowerCase(community) != "oc" && (
                                <>
                                  <Row className="context_box_title mt-2">
                                    <Col md={12}>
                                      <h5>Community certificate details</h5>
                                    </Col>
                                  </Row>
                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Community
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__community"
                                        size="sm"
                                        value={upperCase(community)}
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Name of the Caste
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__caste"
                                        size="sm"
                                        defaultValue={applicant.caste}
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Issuing Authority
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        as="select"
                                        className="text-uppercase fw-bold"
                                        name="i__issuingAuthority"
                                        size="sm"
                                        defaultValue={marksField(
                                          "issuing_authority"
                                        )}
                                      >
                                        <option value="">-Select-</option>
                                        {COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY.map(
                                          (item) => (
                                            <option
                                              value={item}
                                              selected={
                                                item ==
                                                marksField("issuing_authority")
                                                  ? "selected"
                                                  : ""
                                              }
                                            >
                                              {item}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Certificate No
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__certificateNo"
                                        defaultValue={marksField(
                                          "community_certificate_no"
                                        )}
                                        size="sm"
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Date of Issue
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="date"
                                        className="text-uppercase fw-bold"
                                        name="i__issueDate"
                                        size="sm"
                                        defaultValue={marksField(
                                          "community_certificate_issue_on"
                                        )}
                                        max={momentDate(
                                          new Date(),
                                          "YYYY-MM-DD"
                                        )}
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        District
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__district"
                                        size="sm"
                                        defaultValue={marksField("district")}
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Taluk
                                        <span className="text-danger"></span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__taluk"
                                        defaultValue={marksField("taluk")}
                                        size="sm"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {lowerCase(community) != "oc" && (
                                <>
                                  <Row className="context_box_title mt-2">
                                    <Col md={12}>
                                      <h5>Community certificate details</h5>
                                    </Col>
                                  </Row>
                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Community
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__community"
                                        size="sm"
                                        value={upperCase(community)}
                                        required
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Name of the Caste
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__caste"
                                        size="sm"
                                        defaultValue={applicant.caste}
                                        required
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Issuing Authority
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        as="select"
                                        className="text-uppercase fw-bold"
                                        name="i__issuingAuthority"
                                        size="sm"
                                        defaultValue={marksField(
                                          "issuing_authority"
                                        )}
                                        required
                                      >
                                        <option value="">-Select-</option>
                                        {COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY.map(
                                          (item) => (
                                            <option
                                              value={item}
                                              selected={
                                                item ==
                                                marksField("issuing_authority")
                                                  ? "selected"
                                                  : ""
                                              }
                                            >
                                              {item}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Certificate No
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__certificateNo"
                                        defaultValue={marksField(
                                          "community_certificate_no"
                                        )}
                                        size="sm"
                                        required
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Date of Issue
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="date"
                                        className="text-uppercase fw-bold"
                                        name="i__issueDate"
                                        size="sm"
                                        defaultValue={marksField(
                                          "community_certificate_issue_on"
                                        )}
                                        max={momentDate(
                                          new Date(),
                                          "YYYY-MM-DD"
                                        )}
                                        required
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        District
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__district"
                                        size="sm"
                                        defaultValue={marksField("district")}
                                        required
                                      />
                                    </Col>
                                  </Row>

                                  <Row className="py-2 border-bottom">
                                    <Col md={4}>
                                      <label>
                                        Taluk
                                        <span className="text-danger">*</span>
                                      </label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        className="text-uppercase fw-bold"
                                        name="i__taluk"
                                        defaultValue={marksField("taluk")}
                                        required
                                        size="sm"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </>
                          )}

                          <Row className="mt-4">
                            {props.pageType && props.pageType == "edit" && (
                              <Col md={6}>
                                <Link to="/" className="btn btn-danger w-100">
                                  Cancel
                                </Link>
                              </Col>
                            )}
                            <Col
                              md={
                                props.pageType && props.pageType == "edit"
                                  ? 6
                                  : 12
                              }
                            >
                              <Button
                                type="submit"
                                variant="success"
                                className="w-100"
                                disabled={loader}
                              >
                                Save & Continue
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Spin>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div>
            Please check all the filled informations before processing. Do you
            want to proceed next ?
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />
    </>
  );
};

export default StepTwo;
