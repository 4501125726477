import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import StepOne from "../newRegistration/stepOne";
import StepTwo from "../newRegistration/stepTwo";
import StepThree from "../newRegistration/stepThree";
import ApplicationPreview from "../applicationPreview";
import CourseApply from "../courseApply";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ApplicationList from "../applicationList";
import AdmissionInstruction from "../../admissionInstructions";

const Dashboard = (props) => {
  const context = useContext(PsContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const step = queryParams.get("step") ? queryParams.get("step") : "";

  console.log(step);
  if (context.user.registration_completed == "0") {
    if (context.settingValue("register_preferred_course") == 1 && step == "") {
      return (
        <AdmissionInstruction
          {...props}
          instructionPage={"ug-admission-instruction"}
        />
      );
    } else {
      return <StepOne {...props} />;
    }
  } else if (context.user.marks_uploaded == "0") return <StepTwo {...props} />;
  else if (context.user.certificates_uploaded == "0")
    return <StepThree {...props} />;
  else if (context.user.application_declaration == "0")
    return <ApplicationPreview {...props} />;
  else if (
    context.user.regular_course_applied == "0" &&
    context.user.self_course_applied == "0"
  )
    return <CourseApply {...props} />;

  const quickLinks = [
    { text: "Apply Course", icon: "fa-regular fa-clipboard", link: "/apply" },
    { text: "My Profile", icon: "fa-regular fa-user", link: "/my-profile" },
    {
      text: "Logout",
      icon: "fa-solid fa-arrow-right-from-bracket",
      link: "",
      onClick: () => context.logout(),
    },
  ];

  const getQuickLink = (item) => {
    return (
      <div className="py-1">
        <Link to={item.link} onClick={() => item.onClick && item.onClick()}>
          <div className="bg-danger py-3 px-3 text-white d-flex justify-content-between">
            <i className={item.icon}></i>
            <span>{item.text}</span>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="container my-3">
        <Row>
          {quickLinks.map((item, i) => {
            return <Col md={4}>{getQuickLink(item)}</Col>;
          })}
        </Row>

        <Row className="mt-3">
          <Col md={12}>
            <ApplicationList />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
