import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { VENDOR_LOGO } from "../../../utils/data";
import { Col, Row } from "react-bootstrap";
import { brandLogoWide } from "../../../utils";

const Header = (props) => {
  const context = useContext(PsContext);
  let text = window.location.href;
  // let position = text.search("admission");

  return (
    <>
      <header className="header bg-white shadow fixed-top">
        <div className=" header-top">
          <div className="container py-1">
            <Row>
              <Col md={6} className="d-none d-sm-block">
                <a className="text-white">
                  Online admission for the academic year{" "}
                  {context.settingValue("current_academic_year")}
                </a>
              </Col>
              <Col md={6} className="text-end">
                <a className="text-white">
                  <i className="fa-solid fa-phone me-2"></i>{" "}
                  {context.settingValue("admission_support_mobile")},
                  <i className="fa-solid fa-envelope mx-2"></i>
                  {context.settingValue("admission_support_email")}
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container">
          <Row className="my-2">
            <Col md={4}>
              <Link
                to="/home"
                className={
                  context.settingValue("display_wide_logo") == 1
                    ? "logo logo-size"
                    : "logo"
                }
              >
                <img
                  src={brandLogoWide()}
                  alt=""
                  style={{ maxWidth: "120%", maxHeight: "100px" }}
                />
              </Link>
            </Col>
            <Col md={4}></Col>
            <Col md={3} className="d-none d-sm-block">
              <div
                className="d-flex justify-content-center align-items-center flex-column"
                style={{ gap: "10px" }}
              >
                <Link
                  to="/sign-up"
                  className={
                    context.settingValue("display_wide_logo") == 1
                      ? "btn log-btn btn-sm d-block bg-dark btn-success shadow text-white btn-light w-100 py-2 mt-4"
                      : "btn log-btn btn-sm d-block bg-success btn-hover shadow text-white btn-light w-100"
                  }
                >
                  <i class="fa-solid fa-arrow-up-right-from-square me-2"></i>{" "}
                  NEW REGISTRATION
                </Link>
                <Link
                  to="/sign-in"
                  className={
                    context.settingValue("display_wide_logo") == 1
                      ? "btn log-btn btn-sm d-block bg-primary btn-hover shadow text-white btn-light w-100 py-2"
                      : "btn log-btn btn-sm d-block bg-primary btn-hover shadow text-white btn-light w-100"
                  }
                >
                  <i class="fa-solid fa-arrow-up-right-from-square me-2"></i>{" "}
                  SIGN IN
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </header>
    </>
  );
};

export default Header;
